var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"jS4KwmIQDKRUFt0h_diDc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || null,
    beforeSendTransaction(event) {
        if (process.env.NEXT_PUBLIC_NO_FOLLOW || !event.measurements) {
            return null;
        }
        let result = event;
        for (const key in event.measurements) {
            if (event.measurements[key]?.value <= 2000) {
                result = null;
            }
        }
        return result;
    },
    ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: Cancelled',
        'TypeError: cancelled',
        'message: cancelled',
        'cancelled',
        'TypeError: Load failed',
        'SyntaxError: Unexpected token \'function\'',
        'ReferenceError: runCustomize is not defined',
        'ReferenceError: processRandomSelector is not defined',
        'top.GLOBALS',
        'SecurityError: Failed to read the \'sessionStorage\' property from \'Window\': Access is denied for this document',
        'ReferenceError: _reportEvent is not defined',
        'AbortError: The operation was aborted',
        'Invariant: attempted to hard navigate to the same URL',
        'Failed to initialize WebGL',
        'TypeError: Invalid schema configuration',
        'Invalid schema configuration',
        'AxiosError: Network Error',
        'Network Error',
        '__gCrWeb',
        'Event "CustomEvent" (type=error) captured as exception',
        'TypeError: отменено'
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});